/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Container, Icon } from 'semantic-ui-react';
import parse from 'html-react-parser';

import Layout from '../components/layout';
import SEO from '../components/seo';

const StaticPage = ({ data: { page } }) => (
  <Layout>
    <SEO title={page.title} />
    <Container text className="wordpress">
      <h1 style={{ marginTop: '3rem' }}>{page.title}</h1>
      {page.content && parse(page.content)}
      <p>&nbsp;</p>
      <a href="/news">
        <Icon name="angle left" />
        Back to News
      </a>
      <p>&nbsp;</p>
    </Container>
  </Layout>
);

StaticPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape(),
  }).isRequired,
};

export default StaticPage;

export const query = graphql`
  query newsPage($id: String) {
    page: wpPost(id: { eq: $id }) {
      title
      content
    }
  }
`;
